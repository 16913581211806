// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievement-js": () => import("./../../../src/pages/achievement.js" /* webpackChunkName: "component---src-pages-achievement-js" */),
  "component---src-pages-credit-js": () => import("./../../../src/pages/credit.js" /* webpackChunkName: "component---src-pages-credit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-single-js": () => import("./../../../src/pages/member-single.js" /* webpackChunkName: "component---src-pages-member-single-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-research-single-js": () => import("./../../../src/pages/research-single.js" /* webpackChunkName: "component---src-pages-research-single-js" */),
  "component---src-pages-researchs-js": () => import("./../../../src/pages/researchs.js" /* webpackChunkName: "component---src-pages-researchs-js" */)
}

